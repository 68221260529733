import * as React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import { Header } from "../components/atoms";
import { useTranslation } from "gatsby-plugin-react-i18next";

export default function Privacy() {
  const { t } = useTranslation();
  return (
    <Layout title={t("Terms of Use")}>
      <div className="mt-16 max-w-6xl px-8 mx-auto">
        <Header>Terms of Use</Header>
        <div className="pt-8 text-chocolate text-justify">
          <p>
            Welcome to amplified.ai, the website and online service of amplified
            ai, Inc. (“
            <strong>Amplified</strong>
            ,” “<strong>we</strong>
            ,” “<strong>our</strong>
            ,” or “<strong>us</strong>
            ”). This page explains the terms and conditions by which you may use
            our website, including the purchase of Amplified products thereon,
            and our online services and software provided on or in connection
            with the service (collectively the “<strong>Service</strong>
            ”). By registering for an account, accessing or using the Service,
            you signify that you have read, understood, and agree to be bound by
            these Terms of Use (“
            <strong>Terms</strong>
            ”) and to the collection and use of your information as set forth in
            the Amplified Privacy Policy, available at
            <a href="https://app.amplified.ai/privacy">
              https://app.amplified.ai/privacy
            </a>
            , which is hereby incorporated by reference. These Terms applies to
            all visitors, users, and others who access the Service (“
            <strong>Users</strong>
            ”). If you are not eligible, or do not agree to these Terms, then
            please do not use the Service.
          </p>
          <p className="mt-2">
            <strong>
              PLEASE READ THESE TERMS CAREFULLY TO ENSURE THAT YOU UNDERSTAND
              EACH PROVISION.
            </strong>{" "}
            You acknowledge and agree that, as provided in greater detail in
            these Terms:
          </p>
          <ul className="mt-2">
            <li>
              <p>
                We reserve the right to make unilateral modifications to these
                Terms and will provide notice of these changes;
              </p>
            </li>
            <li>
              <p>
                We may charge fees for our Service and products and may set the
                amount of the fees at our sole discretion and may charge you on
                a subscription basis, which is subject to automatic billing;
              </p>
            </li>
            <li>
              <p>
                We do not provide any professional advice through the Service
                and we make no representations, warranties, and/or covenants
                concerning the information provided on the Service; and
              </p>
            </li>
            <li>
              <p>
                These Terms contain a mandatory individual arbitration and class
                action/jury trial waiver provision that requires the use of
                arbitration on an individual basis to resolve disputes, rather
                than jury trials or class actions.
              </p>
            </li>
          </ul>
          <ol type="1">
            <li className="mt-2">
              <h2>
                <span className="underline">Use of Our Service</span>
              </h2>
            </li>
            <li className="mt-2">
              <h3>Eligibility</h3>
            </li>
          </ol>
          <p>
            You may use the Service only if you can form a binding contract with
            Amplified, and only in compliance with these Terms and all
            applicable local, state, national, and international laws, rules and
            regulations. Any use or access to the Service by anyone under
            eighteen (18) years of age is strictly prohibited and in violation
            of these Terms. The Service is not available to any Users previously
            removed from the Service by Amplified.
          </p>
          <ol start="3" type="1">
            <li className="mt-2">
              <h3>Amplified Service</h3>
              <p>
                Subject to the terms and conditions of these Terms, you are
                hereby granted a non-exclusive, limited, non-transferable,
                freely revocable license to use the Service for your personal,
                noncommercial use and only as permitted by the features of the
                Service. Amplified reserves all rights to the Service, the
                Amplified Content (as defined below), and any and all other
                rights not expressly granted herein. Amplified may terminate
                this license at any time for any reason or no reason.
              </p>
            </li>
            <li className="mt-2">
              <h3>Amplified Accounts</h3>
            </li>
          </ol>
          <p>
            To access some features and functionalities of the Service, you may
            be required to register for an Amplified account. We may maintain
            different types of accounts for different types of Users. If you
            open an Amplified account on behalf of a company, organization, or
            other entity, then: (a) “you” includes you and that entity; and (b)
            you represent and warrant that you are an authorized representative
            of the entity with the authority to bind the entity to these Terms,
            and that you agree to these Terms on the entity’s behalf.
          </p>
          <p>
            You may never use another User’s account without permission. When
            creating your account, you must provide accurate and complete
            information. You are solely responsible for the activity that occurs
            on your account, and you must keep your account password secure. We
            encourage you to use “strong” passwords (passwords that use a
            combination of upper and lower case letters, numbers and symbols)
            with your account. You must notify Amplified immediately of any
            breach of security or unauthorized use of your account. Amplified
            will not be liable for any losses caused by any unauthorized use of
            your account.
          </p>
          <p>
            You may control your User profile and how you interact with the
            Service by changing the settings at
            <a href="https://app.amplified.ai/settings">
              https://app.amplified.ai/settings
            </a>
            . By providing Amplified your email address you consent to our using
            the email address to send you Service-related notices, including any
            notices required by law, in lieu of communication by postal mail. We
            may also use your email address to send you other messages, such as
            changes to features of the Service and special offers. If you do not
            want to receive such email messages, you may opt out or change your
            preferences at
            <a href="https://app.amplified.ai/settings">
              https://app.amplified.ai/settings
            </a>
            . Opting out may prevent you from receiving email messages regarding
            updates, improvements, or offers.
          </p>
          <ol start="5" type="1">
            <li className="mt-2">
              <h3>Service Rules</h3>
            </li>
          </ol>
          <p>
            You are solely responsible for your interactions with other Users on
            the Service. You understand that Amplified does not supervise or
            control interactions among or between Users on the Service.
          </p>
          <p>
            We may, without prior notice, change the Service; stop providing the
            Service or features of the Service, to you or to Users generally; or
            create usage limits for the Service. We may permanently or
            temporarily terminate or suspend your access to the Service without
            notice and liability for any reason, including if in our sole
            determination you violate any provision of these Terms, or for no
            reason. Upon termination for any reason or no reason, you continue
            to be bound by these Terms.
          </p>
          <ol start="6" type="1">
            <li>
              <h2 className="my-2">
                <span className="underline">Prohibited Activities</span>
              </h2>
              <p className="my-2 font-bold">
                BY AGREEING TO THESE TERMS, YOU AGREE NOT TO, NOR PERMIT,
                FACILITATE, OR OTHERWISE ASSIST ANYONE ELSE TO, DIRECTLY OR
                INDIRECTLY ENGAGE IN ANY OF THE FOLLOWING ACTIVITIES:
              </p>
              <ol type="i">
                <li className="mb-2">
                  (i) copying, distributing, or disclosing any part of the
                  Service in any medium, including without limitation by any
                  automated or non-automated “scraping”;
                </li>
                <li className="mb-2">
                  (ii) using any automated system, including without limitation
                  “robots,” “spiders,” “offline readers,” etc., to access the
                  Service in a manner that sends more request messages to the
                  Amplified servers than a human can reasonably produce in the
                  same period of time by using a conventional on-line web
                  browser;
                </li>
                <li className="mb-2">
                  (iii) transmitting spam, chain letters, or other unsolicited
                  email;
                </li>
                <li className="mb-2">
                  (iv) attempting to interfere with, compromise the system
                  integrity or security or decipher any transmissions to or from
                  the servers running the Service;
                </li>
                <li className="mb-2">
                  (v) taking any action that imposes, or may impose at our sole
                  discretion an unreasonable or disproportionately large load on
                  our infrastructure;
                </li>
                <li className="mb-2">
                  (vi) uploading invalid data, viruses, worms, or other software
                  agents through the Service;
                </li>
                <li className="mb-2">
                  (vii) collecting or harvesting any personally identifiable
                  information, including account names, from the Service;
                </li>
                <li className="mb-2">
                  (viii) using the Service for any commercial solicitation
                  purposes;
                </li>
                <li className="mb-2">
                  (ix) impersonating another person or otherwise misrepresenting
                  your affiliation with a person or entity, conducting fraud,
                  hiding or attempting to hide your identity;
                </li>
                <li className="mb-2">
                  (x) interfering with the proper working of the Service;
                </li>
                <li className="mb-2">
                  (xi) accessing any content on the Service through any
                  technology or means other than those provided or authorized by
                  the Service; or
                </li>
                <li className="mb-2">
                  (xii) bypassing the measures we may use to prevent or restrict
                  access to the Service, including without limitation features
                  that prevent or restrict use or copying of any content or
                  enforce limitations on use of the Service or the content
                  therein.
                </li>
              </ol>
            </li>
          </ol>
          <p>
            Engaging in any of the foregoing shall constitute a material breach
            of these Terms.
          </p>
          <p>
            The Service is not intended to be a source for bulk downloads of
            data. Users who wish to download large amounts of data should
            contact
            <a href="mailto:support@amplified.ai">support@amplified.ai</a>.
          </p>
          <ol start="7" type="1">
            <li>
              <h2 className="my-2">
                <span className="underline">User Content</span>
              </h2>
            </li>
          </ol>
          <p>
            Some areas of the Service allow Users to create, submit or otherwise
            make available content such as patent information, annotations,
            comments, questions, and other content or information (collectively,
            “<strong>User Content</strong>
            ”). We claim no ownership rights over your User Content. The User
            Content you create, submit or otherwise make available through the
            Service remains yours; however, by sharing User Content through the
            Service, you agree to allow other Users you select to view, edit,
            and/or share your User Content in accordance with your settings and
            these Terms. Amplified has the right (but not the obligation) in its
            sole discretion to remove any User Content that is shared via the
            Service.
          </p>
          <p>
            You agree not to create, submit or otherwise make available User
            Content that: (i) may create a risk of harm, loss, physical or
            mental injury, emotional distress, death, disability, disfigurement,
            or physical or mental illness to you, to any other person, or to any
            animal; (ii) may create a risk of any other loss or damage to any
            person or property; (iii) seeks to harm or exploit children by
            exposing them to inappropriate content, asking for personally
            identifiable details or otherwise; (iv) may constitute or contribute
            to a crime or tort; (v) contains any information or content that we
            deem to be unlawful, harmful, abusive, racially or ethnically
            offensive, defamatory, infringing, invasive of personal privacy or
            publicity rights, harassing, humiliating to other people (publicly
            or otherwise), libelous, threatening, profane, or otherwise
            objectionable; (vi) contains any information or content that is
            illegal (including, without limitation, the disclosure of insider
            information under securities law or of another party’s trade
            secrets); (vii) contains any information or content that you do not
            have a right to make available under any law or under contractual or
            fiduciary relationships; or (viii) contains any information or
            content that you know is not correct and current. You agree your
            User Content does not and will not violate third-party rights of any
            kind, including without limitation any Intellectual Property Rights
            (as defined below) or rights of privacy. Amplified reserves the
            right, but is not obligated, to reject and/or remove any User
            Content that Amplified believes, in its sole discretion, violates
            these provisions.
          </p>
          <p>
            For the purposes of these Terms, “
            <strong>Intellectual Property Rights</strong>” means all patent
            rights, copyright rights, mask work rights, moral rights, rights of
            publicity, trademark, trade dress and service mark rights, goodwill,
            trade secret rights and other intellectual property rights as may
            now exist or hereafter come into existence, and all applications
            therefore and registrations, renewals and extensions thereof, under
            the laws of any state, country, territory or other jurisdiction.
          </p>
          <p>
            In connection with your User Content, you affirm, represent and
            warrant the following:
          </p>
          <ol start="8" type="1">
            <li>
              <blockquote>
                <p>
                  You have the written consent of each and every identifiable
                  natural person in the User Content to use such person’s name
                  or likeness in the manner contemplated by the Service and
                  these Terms, and each such person has released you from any
                  liability that may arise in relation to such use.
                </p>
              </blockquote>
            </li>
            <li>
              <blockquote>
                <p>
                  Your User Content and Amplified’s use thereof as contemplated
                  by these Terms and the Service will not violate any law or
                  infringe any rights of any third party, including but not
                  limited to any Intellectual Property Rights and privacy
                  rights.
                </p>
              </blockquote>
            </li>
            <li>
              <blockquote>
                <p>
                  To the best of your knowledge, all your User Content and other
                  information that you provide to us is truthful and accurate.
                </p>
              </blockquote>
            </li>
          </ol>
          <p>
            Amplified takes no responsibility and assumes no liability for any
            User Content that you or any other User or third party creates,
            submits or otherwise makes available over the Service. You shall be
            solely responsible for your User Content. You understand and agree
            that you may be exposed to User Content that is inaccurate,
            objectionable, inappropriate for children, or otherwise unsuited to
            your purpose, and you agree that Amplified shall not be liable for
            any damages you allege to incur as a result of User Content.
          </p>
          <ol start="11" type="1">
            <li>
              <h2 className="my-2">
                <span className="underline">User Content License Grant</span>
              </h2>
            </li>
          </ol>
          <p>
            By creating, submitting, or otherwise making available any User
            Content on the Service, you hereby expressly grant, and represent
            and warrant that you have all rights necessary to grant, to
            Amplified a royalty-free, sublicensable, transferable, perpetual,
            irrevocable, non-exclusive, worldwide license to use, reproduce,
            modify, edit, translate, distribute, make derivative works or, or
            otherwise exploit your User Content, in whole or in part, and in any
            form, media or technology, whether now known or hereafter developed,
            in each case for the limited purposes of providing and improving the
            Service and developing new services. Amplified will never use your
            User Content for model training without your consent. Custom
            classifiers may be trained using your User Content at your direction
            for the limited purposes of providing the custom classification
            service to you and the users who you select to have access.
          </p>
          <p>
            By creating, sharing, submitting, or otherwise making available any
            User Content on the Service, you grant those Users with who you
            select to share such User Content and such other Users that
            Amplified, in its sole discretion, determines require access to your
            User Content as necessary for the Service a non-exclusive license to
            access and use that User Content as permitted by these Terms and the
            functionality of the Service.
          </p>
          <ol start="12" type="1">
            <li>
              <h2 className="my-2">
                <span className="underline">Our Proprietary Rights</span>
              </h2>
            </li>
          </ol>
          <p>
            Except for your User Content, the Service and all materials therein
            or transferred thereby, including, without limitation, software,
            images, text, graphics, illustrations, logos, patents, trademarks,
            service marks, copyrights, photographs, audio, videos, music, and
            User Content belonging to other Users (the “
            <strong>Amplified Content</strong>
            ”), and all Intellectual Property Rights related thereto, are the
            exclusive property of Amplified and its licensors (including other
            Users who make their User Content available to the Service). Except
            as explicitly provided herein, nothing in these Terms shall be
            deemed to create a license in or under any such Intellectual
            Property Rights, and you agree not to sell, license, rent, modify,
            distribute, copy, reproduce, transmit, publicly display, publicly
            perform, publish, adapt, edit or create derivative works from any
            Amplified Content. Use of the Amplified Content for any purpose not
            expressly permitted by these Terms is strictly prohibited.
          </p>
          <p>
            You may choose to or we may invite you to submit comments or ideas
            about the Service, including without limitation about how to improve
            the Service or our products (“
            <strong>Ideas</strong>
            ”). By submitting any Idea, you agree that your disclosure is
            gratuitous, unsolicited and without restriction and will not place
            Amplified under any fiduciary or other obligation, and that we are
            free to use the Idea without any additional compensation to you,
            and/or to disclose the Idea on a non-confidential basis or otherwise
            to anyone. You further acknowledge that, by acceptance of your
            submission, Amplified does not waive any rights to use similar or
            related ideas previously known to Amplified, or developed by its
            employees, or obtained from sources other than you.
          </p>
          <ol start="13" type="1">
            <li>
              <h2 className="my-2">
                <span className="underline">Paid Services</span>
              </h2>
            </li>
            <li className="mt-2">
              <h3>Payment and Pricing</h3>
              <p>
                If you elect to use paid aspects of the Service, you agree to
                the pricing and payment terms
                <a href="https://app.amplified.ai/pricing">
                  https://app.amplified.ai/pricing
                </a>
                , as we may update them from time to time. Amplified may add new
                services for additional fees and charges, or amend or begin
                charging fees and charges for existing services, at any time in
                its sole discretion.
              </p>
            </li>
            <li className="mt-2">
              <h3>Placing an Order</h3>
              <p>
                After you place an order we will review the information you
                provided for validity by verifying your method of payment,
                billing, and/or shipping address. We may contact you (via email
                or phone) if additional information is required to accept and
                process your order. In valid order information may result in
                delays processing your order. For purchases, your receipt of an
                order confirmation does not constitute Amplified’s acceptance of
                your order. Without prior notification, Amplified maintains the
                right to limit the order quantity and the right to refuse to
                sell products to any customer for any reason or no reason at
                all. Amplified reserves the right not to sell to resellers,
                dealers, or distributors. If your order is canceled, we will
                attempt to notify you using the email address you have given us
                with the order.
              </p>
            </li>
            <li className="mt-2">
              <h3>No Sales to Children</h3>
              <p>
                Amplified does not sell products through its Service for or to
                children, but only to adults, who can purchase with a credit
                card or other permitted payment method. If you are under
                eighteen (18) years of age, you may use the Service only with
                involvement and consent of a parent or legal guardian.
              </p>
            </li>
            <li>
              <h3>Payment Methods</h3>
              <p>
                We accept various payment methods for product purchases through
                our Service, including MasterCard, Visa, and American Express.
                We will bill your payment method when you place an order for a
                product through the Service. Amplified will not fulfill any
                product order without authorization validation of your purchase
                from your payment method.
              </p>
            </li>
            <li className="mt-2">
              <h3>Offers</h3>
              <p>
                You acknowledge and agree that any offers made available through
                the Service are subject to change at any time and from time to
                time
                <strong>.</strong>
              </p>
            </li>
            <li className="mt-2">
              <h3>No Refunds</h3>
              <p>
                You may cancel your Amplified account at any time; however,
                there are no refunds for cancellation. In the event that
                Amplified suspends or terminates your account or these Terms for
                your breach of these Terms, you understand and agree that you
                shall receive no refund or exchange for any pre-paid services
                (including, without limitation, any pre-paid reports), any
                license or subscription fees for any portion of the Service, any
                content or data associated with your account, or for anything
                else.
              </p>
            </li>
            <li className="mt-2">
              <h3>Payment Information; Taxes</h3>
              <p>
                All information that you provide in connection with a purchase
                or transaction or other monetary transaction interaction with
                the Service must be accurate, complete, and current. You agree
                to pay all charges incurred by users of your credit card, debit
                card, or other payment method used in connection with a purchase
                or transaction or other monetary transaction interaction with
                the Service at the prices in effect when such charges are
                incurred. You will pay any applicable taxes, if any, relating to
                any such purchases, transactions or other monetary transaction
                interactions.
              </p>
            </li>
            <li className="mt-2">
              <h3>California Residents</h3>
              <p>
                The provider of services is amplified ai, Inc. [1067 Market
                Street, #4007 San Francisco, CA 94103,{" "}
                <a href="mailto:info@amplified.ai">info@amplified.ai</a>]. If
                you are a California resident, in accordance with Cal. Civ. Code
                §1789.3, you may report complaints to the Complaint Assistance
                Unit of the Division of Consumer Services of the California
                Department of Consumer Affairs by contacting them in writing at
                1625 North Market Blvd., Suite N 112 Sacramento, CA 95834, or by
                telephone at (800) 952-5210 or (916) 445-1254.
              </p>
            </li>
            <li>
              <h2 className="my-2">
                <span className="underline">No Professional Advice</span>
              </h2>
            </li>
          </ol>
          <p>
            If the Service provides professional information (for example, legal
            or financial), such information is for informational purposes only
            and should not be construed as professional advice. No action should
            be taken based upon any information contained in the Service. You
            should seek independent professional advice from a person who is
            licensed and/or qualified in the applicable area.
          </p>
          <ol start="23" type="1">
            <li>
              <h2 className="my-2">
                <span className="underline">Privacy</span>
              </h2>
            </li>
          </ol>
          <p>
            We care about the privacy of our Users. You understand that by using
            the Services you consent to the collection, use, transfer,
            processing and disclosure of your personally identifiable
            information and aggregate data as set forth in our Privacy Policy,
            available at
            <a href="https://app.amplified.ai/privacy">
              https://app.amplified.ai/privacy
            </a>
            .
          </p>
          <ol start="24" type="1">
            <li>
              <h2 className="my-2">
                <span className="underline">Security</span>
              </h2>
            </li>
          </ol>
          <p>
            Amplified uses commercially reasonable physical, managerial, and
            technical safeguards to preserve the integrity and security of your
            personal information and implement your privacy settings. However,
            we cannot guarantee that unauthorized third parties will never be
            able to defeat our security measures or use your personal
            information for improper purposes. You acknowledge that you provide
            your personal information at your own risk.
          </p>
          <ol start="25" type="1">
            <li>
              <h2 className="my-2">
                <span className="underline">Third-Party Links</span>
              </h2>
            </li>
          </ol>
          <p>
            The Service may contain links to third-party websites, advertisers,
            services, special offers, or other events or activities that are not
            owned or controlled by Amplified. Amplified does not endorse or
            assume any responsibility for any such third-party sites,
            information, materials, products, or services. If you access a third
            party website from the Service, you do so at your own risk, and you
            understand that these Terms and the Amplified Privacy Policy do not
            apply to your use of such sites. You expressly relieve Amplified
            from any and all liability arising from your use of any third-party
            website, service, or content. Additionally, your dealings with or
            participation in promotions of advertisers found on the Service,
            including payment and delivery of goods, and any other terms (such
            as warranties) are solely between you and such advertisers. You
            agree that Amplified shall not be responsible for any loss or damage
            of any sort relating to your dealings with such advertisers.
          </p>
          <ol start="26" type="1">
            <li>
              <h2 className="my-2">
                <span className="underline">Indemnity</span>
              </h2>
            </li>
          </ol>
          <p>
            You agree to defend, indemnify and hold harmless Amplified and its
            subsidiaries, agents, licensors, managers, and other affiliated
            companies, and their employees, contractors, agents, officers and
            directors, from and against any and all claims, damages,
            obligations, losses, liabilities, costs or debt, and expenses
            (including but not limited to attorney’s fees) arising from: (i)
            your use of and access to the Service, including any data or content
            transmitted or received by you; (ii) your violation of any term of
            these Terms, including without limitation your breach of any of the
            representations and warranties above; (iii) your violation of any
            third-party right, including without limitation any right of privacy
            or Intellectual Property Rights; (iv) your violation of any
            applicable law, rule or regulation; (v) any claim or damages that
            arise as a result of any of your User Content or any that is
            submitted via your account; or (vi) any other party’s access and use
            of the Service with your unique username, password or other
            appropriate security code.
          </p>
          <ol start="27" type="1">
            <li>
              <h2 className="my-2">
                <span className="underline">No Warranty</span>
              </h2>
            </li>
          </ol>
          <p>
            THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE
            OF THE SERVICE IS AT YOUR OWN RISK. TO THE MAXIMUM EXTENT PERMITTED
            BY APPLICABLE LAW, THE SERVICE IS PROVIDED WITHOUT WARRANTIES OF ANY
            KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, OR NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL
            OR WRITTEN, OBTAINED BY YOU FROM AMPLIFIED OR THROUGH THE SERVICE
            WILL CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. WITHOUT
            LIMITING THE FOREGOING, AMPLIFIED, ITS SUBSIDIARIES, ITS AFFILIATES,
            AND ITS LICENSORS DO NOT WARRANT THAT THE AMPLIFIED CONTENT IS
            ACCURATE, RELIABLE OR CORRECT; THAT THE SERVICE WILL MEET YOUR
            REQUIREMENTS; THAT THE SERVICE WILL BE AVAILABLE AT ANY PARTICULAR
            TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR
            ERRORS WILL BE CORRECTED; OR THAT THE SERVICE IS FREE OF VIRUSES OR
            OTHER HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR OTHERWISE
            OBTAINED THROUGH THE USE OF THE SERVICE IS DOWNLOADED AT YOUR OWN
            RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
            COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD OR
            YOUR USE OF THE SERVICE.
          </p>
          <p>
            The data and information on the legal status of patents are
            displayed as received, without warranty of any kind and for
            information purposes only. The patent authorities from which
            Amplified receives the information on the legal status if patents
            remain solely responsible for the accuracy of the data and
            information they provide. In order to obtain authoritative legal
            status information, users should consult the appropriate national
            patent authority.
          </p>
          <p>
            AMPLIFIED DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
            THIRD PARTY THROUGH THE AMPLIFIED SERVICE OR ANY HYPERLINKED WEBSITE
            OR SERVICE, AND AMPLIFIED WILL NOT BE A PARTY TO OR IN ANY WAY
            MONITOR ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF
            PRODUCTS OR SERVICES.
          </p>
          <p>
            FEDERAL LAW, SOME STATES, PROVINCES AND OTHER JURISDICTIONS DO NOT
            ALLOW EXCLUSIONS AND LIMITATIONS OF CERTAIN IMPLIED WARRANTIES, SO
            SOME OF THE ABOVE LIMITATIONS MAY NOT APPLY TO YOU.
          </p>
          <ol start="28" type="1">
            <li>
              <h2 className="my-2">
                <span className="underline">Limitation of Liability</span>
              </h2>
            </li>
          </ol>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            AMPLIFIED, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS
            OR LICENSORS BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL,
            SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT
            LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
            INTANGIBLE LOSSES, THAT RESULT FROM THE USE OF, OR INABILITY TO USE,
            THIS SERVICE. UNDER NO CIRCUMSTANCES WILL AMPLIFIED BE RESPONSIBLE
            FOR ANY DAMAGE, LOSS OR INJURY RESULTING FROM HACKING, TAMPERING OR
            OTHER UNAUTHORIZED ACCESS OR USE OF THE SERVICE OR YOUR ACCOUNT OR
            THE INFORMATION CONTAINED THEREIN.
          </p>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, AMPLIFIED ASSUMES
            NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR
            INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF
            ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF OUR
            SERVICE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
            SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV)
            ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
            SERVICE; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY
            BE TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY; (VI)
            ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE
            INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED,
            TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE SERVICE; AND/OR
            (VII) USER CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT
            OF ANY THIRD PARTY. IN NO EVENT SHALL AMPLIFIED, ITS AFFILIATES,
            AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR LICENSORS BE LIABLE TO
            YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES,
            LOSSES OR COSTS IN AN AMOUNT EXCEEDING THE AMOUNT YOU PAID TO
            AMPLIFIED HEREUNDER OR $100.00, WHICHEVER IS GREATER.
          </p>
          <p>
            THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED
            LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY,
            OR ANY OTHER BASIS, EVEN IF AMPLIFIED HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGE. THE FOREGOING LIMITATION OF LIABILITY
            SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE
            JURISDICTION.
          </p>
          <p>
            SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL
            OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY
            NOT APPLY TO YOU. THESE TERMS GIVES YOU SPECIFIC LEGAL RIGHTS, AND
            YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE. THE
            DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF LIABILITY UNDER THESE
            TERMS WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
          </p>
          <ol start="29" type="1">
            <li>
              <h2 className="my-2">
                <span className="underline">
                  Governing Law, Arbitration, and Class Action/Jury Trial Waiver
                </span>
              </h2>
            </li>
            <li className="mt-2">
              <h3>Governing Law</h3>
              <p>
                These Terms shall be governed by the internal substantive laws
                of the State of California, without respect to its conflict of
                laws principles. The application of the United Nations
                Convention on Contracts for the International Sale of Goods is
                expressly excluded. You agree to submit to the personal
                jurisdiction of the federal and state courts located in Santa
                Clara County, California for any actions for which we retain the
                right to seek injunctive or other equitable relief in a court of
                competent jurisdiction to prevent the actual or threatened
                infringement, misappropriation or violation of a our copyrights,
                trademarks, trade secrets, patents, or other intellectual
                property or proprietary rights, as set forth in the Arbitration
                provision below, including any provisional relief required to
                prevent irreparable harm. You agree that Santa Clara County,
                California is the proper forum for any appeals of an arbitration
                award or for trial court proceedings in the event that the
                arbitration provision below is found to be unenforceable.
              </p>
            </li>
            <li className="mt-2">
              <h3>Arbitration</h3>
              <p>
                READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO
                ARBITRATE THEIR DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN
                SEEK RELIEF FROM AMPLIFIED. For any dispute with Amplified, you
                agree to first contact us at
                <a href="mailto:support@amplified.ai">
                  support@amplified.ai
                </a>{" "}
                and attempt to resolve the dispute with us informally. In the
                unlikely event that Amplified has not been able to resolve a
                dispute it has with you after sixty (60) days, we each agree to
                resolve any claim, dispute, or controversy (excluding any claims
                for injunctive or other equitable relief as provided below)
                arising out of or in connection with or relating to these Terms,
                or the breach or alleged breach thereof (collectively, “
                <strong>Claims</strong>
                ”), by binding arbitration by JAMS, Inc. (“
                <strong>JAMS</strong>
                ”), under the Optional Expedited Arbitration Procedures then in
                effect for JAMS, except as provided herein. JAMS may be
                contacted at www.jamsadr.com. The arbitration will be conducted
                in Santa Clara County, California, unless you and Amplified
                agree otherwise. If you are using the Service for commercial
                purposes, each party will be responsible for paying any JAMS
                filing, administrative and arbitrator fees in accordance with
                JAMS rules, and the award rendered by the arbitrator shall
                include costs of arbitration, reasonable attorneys’ fees and
                reasonable costs for expert and other witnesses. If you are an
                individual using the Service for non-commercial purposes: (i)
                JAMS may require you to pay a fee for the initiation of your
                case, unless you apply for and successfully obtain a fee waiver
                from JAMS; (ii) the award rendered by the arbitrator may include
                your costs of arbitration, your reasonable attorney’s fees, and
                your reasonable costs for expert and other witnesses; and (iii)
                you may sue in a small claims court of competent jurisdiction
                without first engaging in arbitration, but this does not absolve
                you of your commitment to engage in the informal dispute
                resolution process. Any judgment on the award rendered by the
                arbitrator may be entered in any court of competent
                jurisdiction. Nothing in this Section shall be deemed as
                preventing Amplified from seeking injunctive or other equitable
                relief from the courts as necessary to prevent the actual or
                threatened infringement, misappropriation, or violation of our
                data security, Intellectual Property Rights or other proprietary
                rights.
              </p>
            </li>
            <li className="mt-2">
              <h3>Class Action/Jury Trial Waiver</h3>
              <p>
                WITH RESPECT TO ALL PERSONS AND ENTITIES, REGARDLESS OF WHETHER
                THEY HAVE OBTAINED OR USED THE SERVICE FOR PERSONAL, COMMERCIAL
                OR OTHER PURPOSES, ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’
                INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN
                ANY PURPORTED CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY
                GENERAL ACTION OR OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER
                APPLIES TO CLASS ARBITRATION, AND, UNLESS WE AGREE OTHERWISE,
                THE ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON’S
                CLAIMS. YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND
                AMPLIFIED ARE EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO
                PARTICIPATE IN A CLASS ACTION, COLLECTIVE ACTION, PRIVATE
                ATTORNEY GENERAL ACTION, OR OTHER REPRESENTATIVE PROCEEDING OF
                ANY KIND.
              </p>
            </li>
            <li className="mt-2">
              <h2 className="my-2">
                <span className="underline">General</span>
              </h2>
            </li>
            <li className="mt-2">
              <h3>Assignment</h3>
              <p>
                These Terms, and any rights and licenses granted hereunder, may
                not be transferred or assigned by you, but may be assigned by
                Amplified without restriction. Any attempted transfer or
                assignment in violation hereof shall be null and void.
              </p>
            </li>
            <li className="mt-2">
              <h3>Notification Procedures and Changes to these Terms</h3>
              <p>
                Amplified may provide notifications, whether such notifications
                are required by law or are for marketing or other business
                related purposes, to you via email notice, written or hard copy
                notice, or through posting of such notice on our website, as
                determined by Amplified in our sole discretion. Amplified
                reserves the right to determine the form and means of providing
                notifications to our Users, provided that you may opt out of
                certain means of notification as described in these Terms.
                Amplified is not responsible for any automatic filtering you or
                your network provider may apply to email notifications we send
                to the email address you provide us. Amplified may, in its sole
                discretion, modify or update these Terms from time to time, and
                so you should review this page periodically. When we change the
                Terms in a material manner, we will update the ‘last modified’
                date at the bottom of this page. Your continued use of the
                Service after any such change constitutes your acceptance of the
                new Terms of Use. If you do not agree to any of these terms or
                any future Terms of Use, do not use or access (or continue to
                access) the Service.
              </p>
            </li>
            <li className="mt-2">
              <h3>No Waiver</h3>
              <p>
                No waiver of any term of these Terms shall be deemed a further
                or continuing waiver of such term or any other term, and
                Amplified’s failure to assert any right or provision under these
                Terms shall not constitute a waiver of such right or provision.
              </p>
            </li>
            <li className="mt-2">
              <h3>Notice to California Residents</h3>
              <p>
                If you are a California resident, under California Civil Code
                Section 1789.3, you may contact the Complaint Assistance Unit of
                the Division of Consumer Services of California Department of
                Consumer Affairs in writing at 1625 N. Market Blvd., Suite
                S-202, Sacramento, California 95834, or by telephone at (800)
                952-5210 in order to resolve a complaint regarding the Service
                or to receiver further information regarding the use of the
                Service.
              </p>
            </li>
            <li className="mt-2">
              <h3>Consent to Electronic Communications</h3>
              <p>
                By using the Service, you consent to receiving certain
                electronic communications from us and other users as further
                described in our Privacy Policy. Please read our Privacy Policy
                to learn more about your choices regarding our electronic
                communications practices. You agree that any notices,
                agreements, disclosures, or other communications that we send to
                you electronically will satisfy any legal communication
                requirements, including that such communications be in writing.
              </p>
            </li>
            <li className="mt-2">
              <h3>Additional Terms</h3>
              <p>
                Your use of the Service is subject to any and all additional
                terms, policies, rules, or guidelines applicable to the Service
                or certain features of the Service that we may post on or link
                to on the Service (the “<strong>Additional Terms</strong>
                ”), such as rules applicable to particular features or content
                on the Service. All Additional Terms are hereby incorporated by
                reference into, and made a part of, these Terms.
              </p>
            </li>
            <li className="mt-2">
              <h3>Contact</h3>
              <p>
                Please contact us
                <a href="mailto:support@amplified.ai">support@amplified.ai</a>
                with any questions regarding these Terms.
              </p>
            </li>
            <li className="mt-2">
              <h3>Entire Agreement/Severability</h3>
              <p>
                These Terms, together with any amendments and any additional
                agreements you may enter into with Amplified in connection with
                the Service, shall constitute the entire agreement between you
                and Amplified concerning the Service. If any provision of these
                Terms is deemed invalid by a court of competent jurisdiction,
                the invalidity of such provision shall not affect the validity
                of the remaining provisions of these Terms, which shall remain
                in full force and effect, except that in the event of
                unenforceability of the universal Class Action/Jury Trial
                Waiver, the entire arbitration agreement shall be unenforceable.
              </p>
            </li>
          </ol>
          <p className="mt-4 mb-6">
            <em>These Terms were last modified on June 22, 2023.</em>
          </p>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
